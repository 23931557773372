import React from 'react';
import css from 'src/theme/css';
import Layout from 'src/components/Layout';
import ModuleWrapper from 'src/components/ModuleWrapper';
import { Box } from 'src/components/FlexBox';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import SmartButton from 'src/components/SmartButton';
import Layers from 'src/components/Layers';

export default function Custom404() {
  return (
    <Layout isNavTransparent={true}>
      <ModuleWrapper isHero={true} colorMode="dark">
        <Layers flexDirection="column">
          <Box
            alignItems="center"
            justifyContent="center"
            css={{ position: 'relative' }}
          >
            <Text
              as="h1"
              fontSize={['50vw', null, null, null, '700px']}
              lineHeight={1}
              fontWeight={700}
              fontFamily="aktiv"
              textAlign="center"
              css={css({
                display: 'inline-block',
                mb: -5,
                background: ({ colors }) =>
                  `linear-gradient(to bottom, ${colors.gray600} 20%, black 80%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              })}
            >
              404
            </Text>
          </Box>
          <TextStack
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            css={css({
              px: 'pageMargin',
              mb: [4, 0],
              position: ['relative', 'absolute'],
            })}
          >
            <Text as="h2" variant="heading.xl">
              Oops, something went wrong.
            </Text>
            <Text as="p">
              This page does not exist. Try going back to our homepage.
            </Text>
            <SmartButton variant="Arrow" label="Back to home" action="Link" />
          </TextStack>
        </Layers>
      </ModuleWrapper>
    </Layout>
  );
}
